@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&family=Roboto:wght@700&display=swap");
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");
:root {
  --scope1: #14192d;
  --scope2: #3ebdf3;
  --scope3: #2663c6;
  --scope4: #d4ddea;
  --scopepoint: #f58b1e;
  --gray500: #939498;
  --gray600: #666;
  --gray700: #4d4d4d;
  --line10: rgba(0, 0, 0, 0.1);
  --line20: rgba(0, 0, 0, 0.2);
}

html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
  -webkit-text-size-adjust: none;
}

body {
  overflow-x: auto;
  min-width: 1900px;
}

* {
  padding: 0;
  margin: 0;
  font-family: "Noto Sans KR", sans-serif;
}

body, div, p, h1, h2, h3, h4, h5, h6, ul, ol, li, dl, dt, dd, table, th, td {
  color: #000;
  font-weight: 400;
}

img {
  border: none;
  user-select: none;
}

ul, ol {
  list-style: none;
}

em {
  font-style: normal;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover, a:active, a:focus {
  text-decoration: none;
}

input, button, button:focus, input:focus {
  outline: none;
  border: none;
  background: none;
}

table, td, th {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;
}

textarea {
  letter-spacing: 0;
  outline: none;
  overflow: auto;
  resize: none;
}

.clear {
  clear: both;
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  clear: both;
}

input::placeholder, textarea::placeholder {
  color: #939498;
}

#TSCMSWrap {
  display: grid;
  grid-template-columns: 210px calc(100% - 210px);
}
#TSCMSWrap .TSCMSLnb {
  background-color: #262b41;
  position: sticky;
  left: 0;
  top: 0;
  height: 100vh;
  box-sizing: border-box;
  min-height: 800px;
  overflow-y: auto;
  /*h1 {
      margin: 20px 0 40px 24px;padding-top: 45px;background: url("../images/co2.svg") no-repeat 0 20px;background-size: auto 20px;position: relative;
      &:before {
          content: '';display: none;width: 100%;height: 12px;background: url("../images/cems_logo_f_white.svg") no-repeat 0 0;background-size: auto 100%;position: absolute;top: 0;left: 0;
      }
      span {
          display: block;font-family: "Pretendard Variable", Pretendard, sans-serif;position: relative;color: #fff;font-size: 24px;line-height: 26px;letter-spacing: -0.36px;font-weight: 600;
      }
  }*/
}
#TSCMSWrap .TSCMSLnb h1 {
  margin: 40px 0 40px 24px;
}
#TSCMSWrap .TSCMSLnb h1 span {
  display: block;
  padding-top: 25px;
  background: url("../images/things_logo.svg") no-repeat 0 0;
  background-size: auto 25px;
  font-family: "Pretendard Variable", Pretendard, sans-serif;
  line-height: 28px;
  letter-spacing: -0.36px;
  font-weight: 600;
  color: #fff;
  font-size: 24px;
}
#TSCMSWrap .TSCMSLnb ul {
  list-style-type: none;
  margin: 0;
}
#TSCMSWrap .TSCMSLnb ul li {
  padding: 12px 24px;
}
#TSCMSWrap .TSCMSLnb ul li a {
  display: flex;
  align-items: center;
  height: 22px;
  font-size: 14px;
  color: #fff;
  padding-right: 24px;
}
#TSCMSWrap .TSCMSLnb ul li.on {
  background-color: rgba(62, 189, 243, 0.2);
}
#TSCMSWrap .TSCMSLnb ul li.on a {
  background: url("../images/lnb_arrow.svg") no-repeat 100% 50%;
  color: #3ebdf3;
  font-weight: 700;
}
#TSCMSWrap .TSCMSLnb .LogOutBtn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  height: 40px;
  background-color: #3ebdf3;
  border-radius: 4px;
  color: #fff;
  width: calc(100% - 40px);
}
#TSCMSWrap .TSCMSLnb .weatherInfo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 210px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: none;
}
#TSCMSWrap .TSCMSLnb .weatherInfo dl {
  padding: 30px 24px 40px;
}
#TSCMSWrap .TSCMSLnb .weatherInfo dl dt {
  background: url("../images/ic_location.svg") no-repeat 0 0;
  padding-left: 18px;
  font-size: 10px;
  color: #eee;
  font-weight: 400;
  background-size: 14px auto;
  margin-bottom: 10px;
}
#TSCMSWrap .TSCMSLnb .weatherInfo dl dd {
  display: flex;
  align-items: center;
  gap: 20px;
}
#TSCMSWrap .TSCMSLnb .weatherInfo dl dd p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
  color: #939498;
}
#TSCMSWrap .TSCMSLnb .weatherInfo dl dd p b {
  display: inline-block;
  margin-left: 7px;
  color: #fff;
}
#TSCMSWrap .TSCMSLnb .weatherInfo dl dd strong {
  display: block;
  font-weight: 400;
  font-size: 24px;
  color: #abdaf8;
}
#TSCMSWrap .TSCMSLnb .weatherInfo dl dd:last-child {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 10px;
  padding-top: 10px;
}
#TSCMSWrap .ThingsCarbon {
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
}

.carbonUnit {
  font-style: normal;
  font-size: 55%;
  display: inline-block;
  margin-left: 5px;
}

.ThingsCarbonContent .basicSelect {
  font-size: 16px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 700;
  line-height: normal;
  color: #2b2b2b;
  background-color: #fff;
}
.ThingsCarbonContent .basicSelect .MuiSelect-select {
  padding: 9px 16px;
}
.ThingsCarbonContent .basicSelect fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #d3d3d4;
}
.ThingsCarbonContent .basicSelect.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #3ebdf3;
  border-width: 1px;
}
.ThingsCarbonContent .smallSelect {
  font-size: 14px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
  line-height: normal;
  color: #939498;
  background-color: #fff;
}
.ThingsCarbonContent .smallSelect .MuiSelect-select {
  padding: 6px 14px;
  color: #191919;
}
.ThingsCarbonContent .smallSelect .MuiSelect-select span {
  color: #939498;
}
.ThingsCarbonContent .smallSelect fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #d3d3d4;
}
.ThingsCarbonContent .smallSelect.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #3ebdf3;
  border-width: 1px;
}

.MuiIconButton-root.dialogClose {
  position: absolute;
  right: 30px;
  top: 30px;
  padding: 0;
}

.CarbonbasicTab {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CarbonbasicTab span.unit {
  font-size: 12px;
  color: #666;
  font-weight: 400;
}
.CarbonbasicTab .MuiTabs-root {
  height: 38px;
  min-height: 38px;
}
.CarbonbasicTab .MuiTabs-flexContainer {
  align-items: center;
  justify-content: left;
  gap: 32px;
}
.CarbonbasicTab .MuiTabs-flexContainer button {
  display: block;
  height: 36px;
  box-sizing: border-box;
  border-bottom: 2px solid #fff;
  font-size: 16px;
  font-weight: 700;
  color: #939498;
  min-height: 36px;
  padding: 0;
  font-family: "Noto Sans KR", sans-serif;
  min-width: auto;
}
.CarbonbasicTab .MuiTabs-flexContainer button.Mui-selected {
  color: #14192d;
}
.CarbonbasicTab .MuiTabs-indicator {
  background-color: #14192d;
}

.popSelect.MuiInputBase-root, .popSelect.MuiTextField-root {
  font-size: 14px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 700;
  line-height: normal;
  color: #2b2b2b;
  background-color: #fff;
  width: 176px;
}
.popSelect.MuiInputBase-root .MuiSelect-select, .popSelect.MuiTextField-root .MuiSelect-select {
  padding: 6px 16px;
}
.popSelect.MuiInputBase-root .MuiInputBase-root, .popSelect.MuiTextField-root .MuiInputBase-root {
  padding: 0;
}
.popSelect.MuiInputBase-root .MuiInputBase-root input[type=text], .popSelect.MuiTextField-root .MuiInputBase-root input[type=text] {
  font-size: 14px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 700;
  color: #2b2b2b;
  height: 32px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.popSelect.MuiInputBase-root .MuiInputBase-root input[type=text]::placeholder, .popSelect.MuiTextField-root .MuiInputBase-root input[type=text]::placeholder {
  color: #2b2b2b;
  opacity: 1;
}
.popSelect.MuiInputBase-root fieldset.MuiOutlinedInput-notchedOutline, .popSelect.MuiTextField-root fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #d3d3d4;
}
.popSelect.MuiInputBase-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline, .popSelect.MuiTextField-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #3ebdf3;
  border-width: 1px;
}

.MuiPaper-root.MuiDialog-paper {
  max-width: 1200px;
}

.CarbonReportTable table thead th {
  padding: 12px 16px;
  font-size: 14px;
  color: #4a5568;
  border-color: #a0aec0;
  font-family: "Noto Sans KR", sans-serif;
}
.CarbonReportTable table tbody td {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  color: #2b2b2b;
  border-color: rgba(0, 0, 0, 0.1);
  padding: 12px 16px;
}

.CarbonHistoryTable {
  margin-top: 30px;
}
.CarbonHistoryTable::-webkit-scrollbar {
  width: 4px;
}
.CarbonHistoryTable::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
.CarbonHistoryTable table thead th {
  padding: 12px 16px;
  font-size: 14px;
  color: #4a5568;
  border-color: #a0aec0;
  font-family: "Noto Sans KR", sans-serif;
}
.CarbonHistoryTable table tbody th {
  vertical-align: top;
}
.CarbonHistoryTable table tbody td, .CarbonHistoryTable table tbody th {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  color: #2b2b2b;
  border-color: rgba(0, 0, 0, 0.1);
  padding: 12px 16px;
}

.MuiButtonBase-root.CarbonTableBtn {
  border: none !important;
  background-color: transparent;
  font-family: "Noto Sans KR", sans-serif;
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.CarbonPagination {
  align-items: center;
  margin: 20px 0 30px;
}
.CarbonPagination button.MuiPaginationItem-root {
  color: #666;
  font-size: 14px;
}
.CarbonPagination button.MuiPaginationItem-root.Mui-selected {
  background-color: #3ebdf3;
  color: #fff;
}

.inSelectTitle {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 20px;
  white-space: nowrap;
}
.inSelectTitle .popTitleSelect {
  font-size: 14px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 400;
  line-height: normal;
  color: #939498;
  background-color: #fff;
  width: 200px;
}
.inSelectTitle .popTitleSelect .MuiSelect-select {
  padding: 4px 14px;
  color: #191919;
}
.inSelectTitle .popTitleSelect .MuiSelect-select span {
  color: #939498;
}
.inSelectTitle .popTitleSelect fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #d3d3d4;
}
.inSelectTitle .popTitleSelect.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #3ebdf3;
  border-width: 1px;
}

/* Layout */
.ThingsCarbon {
  background-color: #f2f4f7;
}
.ThingsCarbon .ThingsCarbonContent {
  padding: 40px 50px;
}
.ThingsCarbon .ThingsCarbonContent input[type=text], .ThingsCarbon .ThingsCarbonContent input[type=password], .ThingsCarbon .ThingsCarbonContent input[type=number], .ThingsCarbon .ThingsCarbonContent input[type=email] {
  border: 1px solid #d3d3d4;
  border-radius: 4px;
  padding: 0 14px;
  box-sizing: border-box;
  height: 40px;
  color: #2b2b2b;
}
.ThingsCarbon .ThingsCarbonContent input[type=text]:disabled, .ThingsCarbon .ThingsCarbonContent input[type=password]:disabled, .ThingsCarbon .ThingsCarbonContent input[type=number]:disabled, .ThingsCarbon .ThingsCarbonContent input[type=email]:disabled {
  background-color: #d3d3d4;
}
.ThingsCarbon .ThingsCarbonContent input[type=text]:focus, .ThingsCarbon .ThingsCarbonContent input[type=password]:focus, .ThingsCarbon .ThingsCarbonContent input[type=number]:focus, .ThingsCarbon .ThingsCarbonContent input[type=email]:focus {
  border-color: #3ebdf3;
}
.ThingsCarbon .ThingsCarbonContent input[type=text].error, .ThingsCarbon .ThingsCarbonContent input[type=password].error, .ThingsCarbon .ThingsCarbonContent input[type=number].error, .ThingsCarbon .ThingsCarbonContent input[type=email].error {
  border-color: #ff3939;
}

/* Common Object */
.CarbonPageTitle {
  position: relative;
  display: flex;
  align-items: center;
}
.CarbonPageTitle strong {
  display: block;
  font-size: 24px;
}
.CarbonPageTitle .inSelect {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 12px;
}
.CarbonPageTitle .inSelect strong {
  padding-right: 8px;
}
.CarbonPageTitle .func {
  position: absolute;
  top: 0;
  right: 0;
}
.CarbonPageTitle .func .ty1Button {
  background-color: #3ebdf3;
  border-radius: 4px;
  padding: 8px 20px;
  height: 40px;
  line-height: 24px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

/* DashBoard */
.DashGrid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  margin-top: 30px;
}
.DashGrid .DashGridItem {
  background-color: #fff;
  border-radius: 16px;
  padding: 30px;
}
.DashGrid .DashGridItem.span3 {
  grid-column: span 3;
}
.DashGrid .DashGridItem p.DashItemTitle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.DashGrid .DashGridItem p.DashItemTitle b {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 8px;
}
.DashGrid .DashGridItem p.DashItemTitle b a {
  color: #939498;
}
.DashGrid .DashGridItem p.DashItemTitle b a.on {
  color: #000;
}
.DashGrid .DashGridItem p.DashItemTitle > a {
  font-size: 14px;
  color: #666;
}
.DashGrid .DashGridItem p.DashItemTitle span {
  font-size: 12px;
  color: #666;
}
.DashGrid .DashGridItem .totalCnt {
  text-align: right;
}
.DashGrid .DashGridItem .totalPurpose li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.DashGrid .DashGridItem .totalPurpose li span {
  color: #666;
  font-size: 14px;
  font-weight: 500;
}
.DashGrid .DashGridItem .totalCnt strong, .DashGrid .DashGridItem .totalPurpose strong {
  display: block;
  color: #2b2b2b;
  font-size: 40px;
  margin-bottom: 12px;
}
.DashGrid .DashGridItem .totalCnt em, .DashGrid .DashGridItem .totalPurpose em {
  display: block;
  color: #00a4e0;
  font-size: 24px;
  font-weight: 700;
}
.DashGrid .DashGridItem #Dash1 {
  margin: auto;
  width: 200px;
}
.DashGrid .DashGridItem .userManage {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  height: calc(100% - 40px);
}
.DashGrid .DashGridItem .userManage li {
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 12px;
  position: relative;
}
.DashGrid .DashGridItem .userManage li span {
  color: #666;
  font-size: 14px;
}
.DashGrid .DashGridItem .userManage li b {
  position: absolute;
  bottom: 12px;
  right: 12px;
  font-size: 24px;
}
.DashGrid .DashGridItem table {
  width: 100%;
}
.DashGrid .DashGridItem table thead th {
  padding: 10px 16px;
  color: #4a5568;
  font-size: 14px;
  border-bottom: 1px solid #a0aec0;
  text-align: left;
}
.DashGrid .DashGridItem table tbody td {
  padding: 10px 16px;
  color: #2b2b2b;
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.DashGrid .DashGridItem table tbody td.yellow {
  color: #f58b1e;
}
.DashGrid .DashGridItem table tbody td.red {
  color: #ff3939;
}

/* SectionBox */
.CarbonBox {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgba(147, 148, 152, 0.1);
  padding: 30px;
  position: relative;
}
.CarbonBox .CarbonBoxTitle b {
  display: block;
  font-size: 18px;
  color: #000;
}

.CarbonBox + .CarbonBox {
  margin-top: 20px;
}

.toggleBtn {
  position: relative;
  width: 32px;
  cursor: pointer;
}
.toggleBtn input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.toggleBtn p {
  height: 20px;
  background-color: #d3d3d4;
  border-radius: 10px;
  position: relative;
}
.toggleBtn p em {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: left 0.2s ease;
}
.toggleBtn input:checked + p {
  background-color: #3ebdf3;
}
.toggleBtn input:checked + p em {
  left: 14px;
}

/* 탄소배출량산정 */
.CarbonCalcu .topSwiper {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 34px 50px 0;
  box-sizing: border-box;
  height: 76px;
}
.CarbonCalcu .topSwiper .swiper-slide {
  width: 180px;
  height: 42px;
  font-size: 14px;
  color: #666;
}
.CarbonCalcu .topSwiper .swiper-slide span {
  display: block;
  margin-top: 12px;
  height: 8px;
  border-radius: 20px;
  background-color: #ebebed;
}
.CarbonCalcu .topSwiper .swiper-slide.swiper-slide-active {
  color: #00a4e0;
}
.CarbonCalcu .topSwiper .swiper-slide.swiper-slide-active span {
  background-color: #3ebdf3;
}
.CarbonCalcu .topSwiper .swiper-pagination-fraction {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  bottom: inherit;
  left: inherit;
  font-size: 14px;
  font-weight: 500;
  color: #666;
}
.CarbonCalcu .topSwiper .swiper-pagination-fraction .swiper-pagination-current {
  color: #3ebdf3;
}
.CarbonCalcu .topSwiper .swiper-button-next, .CarbonCalcu .topSwiper .swiper-button-prev {
  width: 50px;
  height: 76px;
  background-color: #fff;
  top: 0;
  margin: 0;
}
.CarbonCalcu .topSwiper .swiper-button-next:after, .CarbonCalcu .topSwiper .swiper-button-prev:after {
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: 47px;
  color: #4d4d4d;
}
.CarbonCalcu .topSwiper .swiper-button-prev {
  left: 0;
}
.CarbonCalcu .topSwiper .swiper-button-prev:after {
  left: 7px;
}
.CarbonCalcu .topSwiper .swiper-button-next {
  right: 0;
}
.CarbonCalcu .topSwiper .swiper-button-next:after {
  right: 7px;
}

.CarbonStepWrap .stepDesc {
  margin: 12px 0 20px;
  font-size: 16px;
  line-height: 24px;
  color: #666;
}
.CarbonStepWrap .CarbonBox .CarbonNavi {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CarbonStepWrap .CarbonBox .CarbonNavi p {
  color: #666;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.CarbonStepWrap .CarbonBox .CarbonNavi p.prev {
  padding-left: 16px;
  background: url("../images/Carbon/ic_prev.svg") no-repeat 0 50%;
}
.CarbonStepWrap .CarbonBox .CarbonNavi p.reset {
  padding-right: 16px;
  background: url("../images/Carbon/ic_rotate.svg") no-repeat 100% 50%;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt3 ul {
  width: 600px;
  margin: auto;
}
.CarbonStepWrap .CarbonBox .CarbonStep ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1240px;
  margin: auto;
  position: relative;
}
.CarbonStepWrap .CarbonBox .CarbonStep ul li {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #939498;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  z-index: 3;
  background-color: #fff;
}
.CarbonStepWrap .CarbonBox .CarbonStep ul:before {
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  background-color: #ebebed;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.CarbonStepWrap .CarbonBox .CarbonStep ul:after {
  content: "";
  display: block;
  width: 0;
  height: 4px;
  background-color: #14192d;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  animation-direction: alternate;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt3 ul.step1:after {
  animation: stepCnt3_1prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt3 ul.step1 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt3 ul.step2:after {
  animation: stepCnt3_2prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt3 ul.step2 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt3 ul.step2 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt3 ul.step3:after {
  animation: stepCnt3_3prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt3 ul.step3 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt3 ul.step3 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt3 ul.step3 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt3 ul.step4 {
  display: none;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt4 ul.step1:after {
  animation: stepCnt4_1prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt4 ul.step1 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt4 ul.step2:after {
  animation: stepCnt4_2prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt4 ul.step2 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt4 ul.step2 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt4 ul.step3:after {
  animation: stepCnt4_3prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt4 ul.step3 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt4 ul.step3 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt4 ul.step3 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt4 ul.step4:after {
  animation: stepCnt4_4prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt4 ul.step4 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt4 ul.step4 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt4 ul.step4 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt4 ul.step4 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt4 ul.step5 {
  display: none;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step1:after {
  animation: stepCnt5_1prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step1 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step2:after {
  animation: stepCnt5_2prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step2 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step2 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step3:after {
  animation: stepCnt5_3prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step3 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step3 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step3 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step4:after {
  animation: stepCnt5_4prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step4 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step4 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step4 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step4 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step5:after {
  animation: stepCnt5_5prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step5 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step5 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step5 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step5 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step5 li:nth-child(5) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt5 ul.step6 {
  display: none;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step1:after {
  animation: stepCnt6_1prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step1 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step2:after {
  animation: stepCnt6_2prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step2 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step2 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step3:after {
  animation: stepCnt6_3prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step3 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step3 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step3 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step4:after {
  animation: stepCnt6_4prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step4 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step4 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step4 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step4 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step5:after {
  animation: stepCnt6_5prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step5 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step5 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step5 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step5 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step5 li:nth-child(5) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step6:after {
  animation: stepCnt6_6prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step6 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step6 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step6 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step6 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step6 li:nth-child(5) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step6 li:nth-child(6) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt6 ul.step7 {
  display: none;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step1:after {
  animation: stepCnt7_1prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step1 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step2:after {
  animation: stepCnt7_2prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step2 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step2 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step3:after {
  animation: stepCnt7_3prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step3 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step3 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step3 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step4:after {
  animation: stepCnt7_4prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step4 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step4 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step4 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step4 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step5:after {
  animation: stepCnt7_5prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step5 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step5 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step5 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step5 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step5 li:nth-child(5) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step6:after {
  animation: stepCnt7_6prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step6 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step6 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step6 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step6 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step6 li:nth-child(5) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step6 li:nth-child(6) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step7:after {
  animation: stepCnt7_7prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step7 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step7 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step7 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step7 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step7 li:nth-child(5) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step7 li:nth-child(6) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step7 li:nth-child(7) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt7 ul.step8 {
  display: none;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step1:after {
  animation: stepCnt8_1prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step1 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step2:after {
  animation: stepCnt8_2prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step2 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step2 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step3:after {
  animation: stepCnt8_3prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step3 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step3 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step3 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step4:after {
  animation: stepCnt8_4prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step4 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step4 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step4 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step4 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step5:after {
  animation: stepCnt8_5prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step5 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step5 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step5 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step5 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step5 li:nth-child(5) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step6:after {
  animation: stepCnt8_6prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step6 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step6 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step6 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step6 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step6 li:nth-child(5) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step6 li:nth-child(6) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step7:after {
  animation: stepCnt8_7prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step7 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step7 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step7 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step7 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step7 li:nth-child(5) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step7 li:nth-child(6) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step7 li:nth-child(7) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step8:after {
  animation: stepCnt8_8prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step8 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step8 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step8 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step8 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step8 li:nth-child(5) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step8 li:nth-child(6) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step8 li:nth-child(7) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step8 li:nth-child(8) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt8 ul.step9 {
  display: none;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step1:after {
  animation: stepCnt9_1prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step1 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step2:after {
  animation: stepCnt9_2prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step2 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step2 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step3:after {
  animation: stepCnt9_3prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step3 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step3 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step3 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step4:after {
  animation: stepCnt9_4prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step4 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step4 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step4 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step4 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step5:after {
  animation: stepCnt9_5prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step5 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step5 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step5 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step5 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step5 li:nth-child(5) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step6:after {
  animation: stepCnt9_6prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step6 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step6 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step6 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step6 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step6 li:nth-child(5) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step6 li:nth-child(6) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step7:after {
  animation: stepCnt9_7prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step7 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step7 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step7 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step7 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step7 li:nth-child(5) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step7 li:nth-child(6) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step7 li:nth-child(7) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step8:after {
  animation: stepCnt9_8prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step8 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step8 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step8 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step8 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step8 li:nth-child(5) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step8 li:nth-child(6) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step8 li:nth-child(7) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step8 li:nth-child(8) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step9:after {
  animation: stepCnt9_9prog 0.5s forwards;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step9 li:nth-child(1) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step9 li:nth-child(2) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step9 li:nth-child(3) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step9 li:nth-child(4) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step9 li:nth-child(5) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step9 li:nth-child(6) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step9 li:nth-child(7) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step9 li:nth-child(8) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step9 li:nth-child(9) {
  background-color: #14192d;
  color: #fff;
  border-color: #14192d;
}
.CarbonStepWrap .CarbonBox .CarbonStep.stepCnt9 ul.step10 {
  display: none;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent {
  height: 544px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent strong {
  font-size: 24px;
  font-weight: 700;
  color: #2b2b2b;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent strong em {
  color: #3ebdf3;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .stepBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 130px;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .stepBtn > button {
  width: 245px;
  height: 48px;
  background-color: #ebebed;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #2b2b2b;
  transition: all 0.3s linear;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .stepBtn > button:hover, .CarbonStepWrap .CarbonBox .CarbonStepContent .stepBtn > button.on {
  background-color: #3ebdf3;
  color: #fff;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .stepHash {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 1280px;
  margin-top: 72px;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .stepHash > div {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 16px 40px;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;
  color: #666;
  border-radius: 99px;
  cursor: pointer;
  transition: all 0.3s linear;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .stepHash > div:hover, .CarbonStepWrap .CarbonBox .CarbonStepContent .stepHash > div.on {
  background-color: #3ebdf3;
  color: #fff;
  border-color: #3ebdf3;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .selectWrap {
  width: 500px;
  margin-top: 50px;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .selectWrap > div {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .selectWrap > div label {
  width: 200px;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .selectWrap > div > div {
  width: 300px;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .selectWrap > div > div .MuiSelect-select {
  font-weight: 400;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .Scope3Chart {
  width: 770px;
  box-sizing: border-box;
  background-color: #f5f7fa;
  padding: 0 10px;
  margin-top: 60px;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .Scope3ChartLegend {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  gap: 16px;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .Scope3ChartLegend p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .Scope3ChartLegend p em {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .Scope3ChartLegend p span {
  font-size: 12px;
  color: #666;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .Scope3ChartLegend p b {
  font-size: 14px;
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .Scope3ChartLegend p:nth-child(1) em {
  background-color: var(--scope2);
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .Scope3ChartLegend p:nth-child(2) em {
  background-color: var(--scope3);
}
.CarbonStepWrap .CarbonBox .CarbonStepContent .Scope3ChartLegend p:nth-child(3) em {
  background-color: var(--scope1);
}
.CarbonStepWrap .StepSubmit {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 16px;
}
.CarbonStepWrap .StepSubmit button {
  border-radius: 4px;
  background-color: #3ebdf3;
  color: #fff;
  font-size: 16px;
  padding: 8px 20px;
}

@keyframes stepCnt3_2prog {
  0% {
    width: 0px;
  }
  100% {
    width: 280px;
  }
}
@keyframes stepCnt3_3prog {
  0% {
    width: 280px;
  }
  100% {
    width: 560px;
  }
}
@keyframes stepCnt4_2prog {
  0% {
    width: 0px;
  }
  100% {
    width: 400px;
  }
}
@keyframes stepCnt4_3prog {
  0% {
    width: 400px;
  }
  100% {
    width: 800px;
  }
}
@keyframes stepCnt4_4prog {
  0% {
    width: 800px;
  }
  100% {
    width: 1200px;
  }
}
@keyframes stepCnt5_2prog {
  0% {
    width: 0px;
  }
  100% {
    width: 300px;
  }
}
@keyframes stepCnt5_3prog {
  0% {
    width: 300px;
  }
  100% {
    width: 600px;
  }
}
@keyframes stepCnt5_4prog {
  0% {
    width: 600px;
  }
  100% {
    width: 900px;
  }
}
@keyframes stepCnt5_5prog {
  0% {
    width: 900px;
  }
  100% {
    width: 1200px;
  }
}
@keyframes stepCnt6_2prog {
  0% {
    width: 0px;
  }
  100% {
    width: 240px;
  }
}
@keyframes stepCnt6_3prog {
  0% {
    width: 240px;
  }
  100% {
    width: 480px;
  }
}
@keyframes stepCnt6_4prog {
  0% {
    width: 480px;
  }
  100% {
    width: 720px;
  }
}
@keyframes stepCnt6_5prog {
  0% {
    width: 720px;
  }
  100% {
    width: 960px;
  }
}
@keyframes stepCnt6_6prog {
  0% {
    width: 960px;
  }
  100% {
    width: 1200px;
  }
}
@keyframes stepCnt7_2prog {
  0% {
    width: 0px;
  }
  100% {
    width: 200px;
  }
}
@keyframes stepCnt7_3prog {
  0% {
    width: 200px;
  }
  100% {
    width: 400px;
  }
}
@keyframes stepCnt7_4prog {
  0% {
    width: 400px;
  }
  100% {
    width: 600px;
  }
}
@keyframes stepCnt7_5prog {
  0% {
    width: 600px;
  }
  100% {
    width: 800px;
  }
}
@keyframes stepCnt7_6prog {
  0% {
    width: 800px;
  }
  100% {
    width: 1000px;
  }
}
@keyframes stepCnt7_7prog {
  0% {
    width: 1000px;
  }
  100% {
    width: 1200px;
  }
}
@keyframes stepCnt8_2prog {
  0% {
    width: 0px;
  }
  100% {
    width: 172px;
  }
}
@keyframes stepCnt8_3prog {
  0% {
    width: 172px;
  }
  100% {
    width: 344px;
  }
}
@keyframes stepCnt8_4prog {
  0% {
    width: 344px;
  }
  100% {
    width: 516px;
  }
}
@keyframes stepCnt8_5prog {
  0% {
    width: 516px;
  }
  100% {
    width: 688px;
  }
}
@keyframes stepCnt8_6prog {
  0% {
    width: 688px;
  }
  100% {
    width: 860px;
  }
}
@keyframes stepCnt8_7prog {
  0% {
    width: 860px;
  }
  100% {
    width: 1032px;
  }
}
@keyframes stepCnt8_8prog {
  0% {
    width: 1032px;
  }
  100% {
    width: 1204px;
  }
}
@keyframes stepCnt9_2prog {
  0% {
    width: 0px;
  }
  100% {
    width: 150px;
  }
}
@keyframes stepCnt9_3prog {
  0% {
    width: 150px;
  }
  100% {
    width: 300px;
  }
}
@keyframes stepCnt9_4prog {
  0% {
    width: 300px;
  }
  100% {
    width: 450px;
  }
}
@keyframes stepCnt9_5prog {
  0% {
    width: 450px;
  }
  100% {
    width: 600px;
  }
}
@keyframes stepCnt9_6prog {
  0% {
    width: 600px;
  }
  100% {
    width: 750px;
  }
}
@keyframes stepCnt9_7prog {
  0% {
    width: 750px;
  }
  100% {
    width: 900px;
  }
}
@keyframes stepCnt9_8prog {
  0% {
    width: 900px;
  }
  100% {
    width: 1050px;
  }
}
@keyframes stepCnt9_9prog {
  0% {
    width: 1050px;
  }
  100% {
    width: 1200px;
  }
}
.CarbonPurposeWrap .PurposeInputWrap {
  width: 804px;
  margin: auto;
}
.CarbonPurposeWrap .PurposeInputWrap .PurposeInput {
  margin-top: 40px;
}
.CarbonPurposeWrap .PurposeInputWrap .PurposeInput div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 90px;
  gap: 40px;
  margin-bottom: 16px;
}
.CarbonPurposeWrap .PurposeInputWrap .PurposeInput div label {
  display: block;
  width: 100%;
  color: #666;
  font-size: 18px;
  font-weight: 500;
}
.CarbonPurposeWrap .PurposeInputWrap .PurposeInput div input {
  width: 100%;
}
.CarbonPurposeWrap .PurposeInputWrap .PurposeSum {
  margin-top: 40px;
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 24px;
  font-weight: 700;
  color: #2b2b2b;
}
.CarbonPurposeWrap .PurposeInputWrap .PurposeSum input {
  width: 80px;
  text-align: center;
  font-size: 16px;
  margin: 0 10px 0 20px;
  color: #00a4e0 !important;
}
.CarbonPurposeWrap .PurposeInputWrap .btn {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CarbonPurposeWrap .PurposeInputWrap .btn > button {
  width: 300px;
  height: 48px;
  border-radius: 4px;
  background-color: #3ebdf3;
}
.CarbonPurposeWrap .PurposeInputWrap .btn > button span {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.CarbonPurposeWrap .PurposeInputWrap .btn > button:disabled {
  background-color: #ebebed;
}
.CarbonPurposeWrap .PurposeInputWrap .btn > button:disabled span {
  color: #2b2b2b;
  opacity: 0.3;
}
.CarbonPurposeWrap .PurposeSubmit {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 16px 0 20px;
  gap: 10px;
}
.CarbonPurposeWrap .PurposeSubmit > button {
  border-radius: 4px;
  background-color: #3ebdf3;
  color: #fff;
  font-size: 16px;
  padding: 8px 20px;
  min-width: 140px;
  font-weight: 500;
  cursor: pointer;
}
.CarbonPurposeWrap .PurposeSubmit > button.cancle {
  background-color: #ebebed;
  color: #2b2b2b;
}
.CarbonPurposeWrap .scenarioGrid {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  margin-top: 24px;
  gap: 0;
}
.CarbonPurposeWrap .scenarioGrid .noData {
  background-color: #f2f4f7;
  height: 262px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 14px;
  color: #939498;
}
.CarbonPurposeWrap .scenarioGrid .scenarioFile {
  margin-right: 10px;
  padding-right: 10px;
  height: 280px;
  overflow-y: auto;
}
.CarbonPurposeWrap .scenarioGrid .scenarioFile::-webkit-scrollbar {
  width: 4px;
}
.CarbonPurposeWrap .scenarioGrid .scenarioFile::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
.CarbonPurposeWrap .scenarioGrid .scenarioFile ul li {
  height: 46px;
  background-color: #f7f7f7;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  margin-bottom: 8px;
  border: 1px solid #f7f7f7;
}
.CarbonPurposeWrap .scenarioGrid .scenarioFile ul li:last-child {
  margin-bottom: 0;
}
.CarbonPurposeWrap .scenarioGrid .scenarioFile ul li p {
  position: relative;
}
.CarbonPurposeWrap .scenarioGrid .scenarioFile ul li p input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.CarbonPurposeWrap .scenarioGrid .scenarioFile ul li p label {
  padding-left: 28px;
  font-size: 14px;
  color: #666;
  background: url("../images/Carbon/chk_off.svg") no-repeat 0 50%;
}
.CarbonPurposeWrap .scenarioGrid .scenarioFile ul li p input:checked + label {
  background-image: url("../images/Carbon/chk_on.svg");
}
.CarbonPurposeWrap .scenarioGrid .scenarioFile ul li .del {
  background: url("../images/Carbon/ic_close.svg") no-repeat 50% 50%;
  width: 20px;
  height: 20px;
  display: block;
}
.CarbonPurposeWrap .scenarioGrid .scenarioFile ul li.select {
  border-color: #3ebdf3;
  background-color: rgba(62, 189, 243, 0.2);
}
.CarbonPurposeWrap .scenarioGrid .scenarioFile ul li.select p label {
  color: #2b2b2b;
}
.CarbonPurposeWrap .scenarioGrid .scenarioFile .noData {
  background-color: #f7f7f7;
}
.CarbonPurposeWrap .scenarioGrid .scenarioDetail {
  background-color: #f2f4f7;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.CarbonPurposeWrap .scenarioGrid .scenarioDetail ul {
  padding: 0 30px;
  width: 100%;
  box-sizing: border-box;
}
.CarbonPurposeWrap .scenarioGrid .scenarioDetail ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.CarbonPurposeWrap .scenarioGrid .scenarioDetail ul li:last-child {
  margin-bottom: 0;
}
.CarbonPurposeWrap .scenarioGrid .scenarioDetail ul li span {
  font-size: 14px;
  color: #666;
  font-weight: 500;
}
.CarbonPurposeWrap .scenarioGrid .scenarioDetail ul li b {
  font-size: 24px;
  color: #2b2b2b;
}
.CarbonPurposeWrap .scenarioBtmText {
  font-size: 14px;
  color: #666;
  text-align: right;
  margin-top: 8px;
}
.CarbonPurposeWrap .scenarioBtn {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 16px 0;
}
.CarbonPurposeWrap .scenarioBtn > button {
  background-color: #4a5568;
  color: #fff;
  padding: 0 20px;
  border-radius: 4px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
}
.CarbonPurposeWrap .GraphInBtn {
  position: absolute;
  top: 27px;
  right: 30px;
  z-index: 3;
}
.CarbonPurposeWrap .GraphInBtn > button {
  background-color: #4a5568;
  color: #fff;
  padding: 0 14px;
  border-radius: 4px;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
}

.reportTitle > span {
  font-size: 14px;
  color: #2b2b2b;
  display: flex;
  align-items: center;
  font-weight: 400;
}
.reportTitle > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.reportTitle > div strong {
  font-size: 40px;
}
.reportTitle > div ul li {
  font-size: 14px;
  color: #666;
  line-height: 22px;
}

.CarbonPopCont {
  margin-top: 20px;
}
.CarbonPopCont input[type=text], .CarbonPopCont input[type=password], .CarbonPopCont input[type=number], .CarbonPopCont input[type=email] {
  border: 1px solid #d3d3d4;
  border-radius: 4px;
  padding: 0 14px;
  box-sizing: border-box;
  height: 40px;
  color: #2b2b2b;
}
.CarbonPopCont input[type=text]:disabled, .CarbonPopCont input[type=password]:disabled, .CarbonPopCont input[type=number]:disabled, .CarbonPopCont input[type=email]:disabled {
  background-color: #d3d3d4;
}
.CarbonPopCont input[type=text]:focus, .CarbonPopCont input[type=password]:focus, .CarbonPopCont input[type=number]:focus, .CarbonPopCont input[type=email]:focus {
  border-color: #3ebdf3;
}
.CarbonPopCont input[type=text].error, .CarbonPopCont input[type=password].error, .CarbonPopCont input[type=number].error, .CarbonPopCont input[type=email].error {
  border-color: #ff3939;
}
.CarbonPopCont .CarbonPopBtn {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
.CarbonPopCont .CarbonPopBtn > button {
  cursor: pointer;
  width: 100%;
  background-color: #3ebdf3;
  color: #fff;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
}
.CarbonPopCont .CarbonPopBtn > button.cancle {
  background-color: #ebebed;
  color: #2b2b2b;
}
.CarbonPopCont .typoTy1 {
  font-size: 14px;
  color: #666;
}
.CarbonPopCont .inForm {
  margin-top: 20px;
}
.CarbonPopCont .inForm input[type=text], .CarbonPopCont .inForm input[type=number], .CarbonPopCont .inForm input[type=password], .CarbonPopCont .inForm input[type=email] {
  width: 100%;
  margin-bottom: 12px;
}
.CarbonPopCont .CarbonPopBtmText {
  font-size: 12px;
  color: #939498;
  margin-top: 18px;
  margin-bottom: 32px;
}
.CarbonPopCont .confirmMsg {
  text-align: center;
  font-size: 14px;
  color: #666;
}
.CarbonPopCont .CarbonPopFileUpload {
  min-width: 600px;
}
.CarbonPopCont .CarbonPopFileUpload .topMsg {
  font-size: 14px;
  font-weight: 500;
  color: #2b2b2b;
}
.CarbonPopCont .CarbonPopFileUpload .fileUploadForm {
  margin-top: 20px;
}
.CarbonPopCont .CarbonPopFileUpload .fileUploadForm dl dt {
  font-size: 14px;
  color: #666;
  margin-bottom: 12px;
}
.CarbonPopCont .CarbonPopFileUpload .fileUploadForm dl dd {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  flex-direction: column;
  overflow: hidden;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  background-color: #f7f7f7;
  height: 210px;
}
.CarbonPopCont .CarbonPopFileUpload .fileUploadForm dl dd p {
  background: url("../images/Carbon/ic_upfile.svg") no-repeat 0 50%;
  padding-left: 32px;
  height: 24px;
  line-height: 24px;
  color: #939498;
  font-size: 14px;
}
.CarbonPopCont .CarbonPopFileUpload .fileUploadForm dl dd em {
  display: block;
  color: #939498;
  font-size: 14px;
  margin: 16px 0;
}
.CarbonPopCont .CarbonPopFileUpload .fileUploadForm dl dd button {
  padding: 0 14px;
  height: 32px;
  border-radius: 4px;
  background-color: #4a5568;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.CarbonPopCont .CarbonPopFileUpload .fileUploadForm dl dd div.warning {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 36px;
  background-color: rgba(255, 57, 57, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.CarbonPopCont .CarbonPopFileUpload .fileUploadForm dl dd div.warning span {
  color: #ff3939;
  display: block;
  padding-left: 28px;
  font-size: 12px;
  line-height: 20px;
  background: url("../images/Carbon/ic_danger.svg") no-repeat 0 50%;
}
.CarbonPopCont .CarbonPopFileUpload .uploadedList {
  margin-top: 20px;
}
.CarbonPopCont .CarbonPopFileUpload .uploadedList dl dt {
  font-size: 14px;
  color: #666;
  margin-bottom: 12px;
  position: relative;
}
.CarbonPopCont .CarbonPopFileUpload .uploadedList dl dt b {
  color: #2b2b2b;
}
.CarbonPopCont .CarbonPopFileUpload .uploadedList dl dt .btnArea {
  display: flex;
  align-items: center;
  justify-content: end;
  position: absolute;
  top: 0;
  right: 0;
  gap: 10px;
}
.CarbonPopCont .CarbonPopFileUpload .uploadedList dl dt .btnArea button {
  font-size: 14px;
  color: #666;
}
.CarbonPopCont .CarbonPopFileUpload .uploadedList dl dd {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 16px 0;
}
.CarbonPopCont .CarbonPopFileUpload .uploadedList dl dd ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.CarbonPopCont .CarbonPopFileUpload .uploadedList dl dd ul li p.fileName {
  font-size: 14px;
  color: #2b2b2b;
}
.CarbonPopCont .CarbonPopFileUpload .uploadedList dl dd ul li p.fileName span {
  display: inline-block;
  color: #666;
}
.CarbonPopCont .CarbonPopFileUpload .uploadedList dl dd ul li p.fileBtn {
  overflow: hidden;
}
.CarbonPopCont .CarbonPopFileUpload .uploadedList dl dd ul li p.fileBtn button {
  width: 16px;
  height: 16px;
  margin-left: 12px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.CarbonPopCont .CarbonPopFileUpload .uploadedList dl dd ul li p.fileBtn button.down {
  background-image: url("../images/Carbon/ic_arrowdown.svg");
}
.CarbonPopCont .CarbonPopFileUpload .uploadedList dl dd ul li p.fileBtn button.del {
  background-image: url("../images/Carbon/ic_close.svg");
  background-size: 16px auto;
}
.CarbonPopCont .popScenario {
  width: 660px;
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
}
.CarbonPopCont .popScenario .ScenarioSelect ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.CarbonPopCont .popScenario .ScenarioSelect ul li:last-child {
  margin-bottom: 0;
}
.CarbonPopCont .popScenario .ScenarioSelect ul li label {
  font-size: 14px;
  color: #666;
}
.CarbonPopCont .popScenario .ScenarioResult {
  border-radius: 8px;
  background-color: #f2f4f7;
  padding: 30px 20px;
}
.CarbonPopCont .popScenario .ScenarioResult ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.CarbonPopCont .popScenario .ScenarioResult ul li:last-child {
  margin-bottom: 0;
}
.CarbonPopCont .popScenario .ScenarioResult ul label {
  font-size: 14px;
  color: #666;
}
.CarbonPopCont .popScenario .ScenarioResult ul b {
  font-size: 16px;
}
.CarbonPopCont .logTextSelect {
  width: 660px;
}
.CarbonPopCont .logTextSelect ul {
  max-height: 320px;
  overflow-y: scroll;
  padding-right: 20px;
}
.CarbonPopCont .logTextSelect ul::-webkit-scrollbar {
  width: 4px;
}
.CarbonPopCont .logTextSelect ul::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
.CarbonPopCont .logTextSelect ul li {
  position: relative;
  margin-bottom: 20px;
}
.CarbonPopCont .logTextSelect ul li input[type=radio] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.CarbonPopCont .logTextSelect ul li p {
  padding-left: 36px;
  background: url("../images/Carbon/radio_off.svg") no-repeat 0 50%;
  font-size: 16px;
  color: #666;
  line-height: 22px;
  font-weight: 500;
}
.CarbonPopCont .logTextSelect ul li input[type=radio]:checked + p {
  background-image: url("../images/Carbon/radio_on.svg");
}
.CarbonPopCont .doubleSel {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 20px 0 32px;
}
.CarbonPopCont .doubleSel > div {
  width: 50%;
}
.CarbonPopCont .CarbonReportPop {
  width: 1100px;
}
.CarbonPopCont .CarbonReportPop span.unit {
  font-size: 12px;
  color: #666;
  font-weight: 400;
}
.CarbonPopCont .CarbonReportPop dl {
  margin-bottom: 40px;
}
.CarbonPopCont .CarbonReportPop dl dt {
  border-bottom: 1px solid #a0aec0;
  padding-bottom: 12px;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CarbonPopCont .CarbonReportPop dl dd > strong {
  display: block;
  font-size: 40px;
  margin: 24px 0 12px;
}
.CarbonPopCont .CarbonReportPop dl dd .grayBox {
  background-color: #f2f4f7;
  padding: 16px;
  font-size: 14px;
  color: #2b2b2b;
}
.CarbonPopCont .CarbonReportPop dl dd table {
  width: 100%;
}
.CarbonPopCont .CarbonReportPop dl dd table thead th {
  border-bottom: 1px solid #a0aec0;
  height: 46px;
  font-size: 14px;
  text-align: left;
  padding: 0 16px;
  color: #4a5568;
}
.CarbonPopCont .CarbonReportPop dl dd table tbody td, .CarbonPopCont .CarbonReportPop dl dd table tbody th {
  padding: 0 16px;
  height: 46px;
  font-size: 14px;
  color: #2b2b2b;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: left;
}
.CarbonPopCont .CarbonReportPop dl dd table tbody td .second500 {
  color: #00a4e0;
}
.CarbonPopCont .CarbonReportPop dl dd table tfoot {
  background-color: #f3f9fe;
  border-top: 1px solid #a0aec0;
}
.CarbonPopCont .CarbonReportPop dl dd table tfoot th, .CarbonPopCont .CarbonReportPop dl dd table tfoot td {
  padding: 0 16px;
  height: 46px;
  font-size: 14px;
  color: #2b2b2b;
  text-align: left;
  font-weight: 700;
}
.CarbonPopCont .CarbonReportPop dl dd .chartTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 12px;
}
.CarbonPopCont .CarbonReportPop dl dd .chartTitle b {
  font-size: 14px;
  color: #666;
  font-weight: 500;
}
.CarbonPopCont .CarbonReportPop dl dd .chartScope {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  align-items: center;
}
.CarbonPopCont .CarbonReportPop dl dd .chartScope ul li {
  position: relative;
  padding-top: 18px;
  margin-top: 18px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.CarbonPopCont .CarbonReportPop dl dd .chartScope ul li > em {
  display: block;
  font-size: 14px;
  color: #2b2b2b;
}
.CarbonPopCont .CarbonReportPop dl dd .chartScope ul li strong {
  font-size: 24px;
}
.CarbonPopCont .CarbonReportPop dl dd .chartScope ul li:first-child {
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}
.CarbonPopCont .CarbonReportPop dl dd .chartScope ul li u {
  position: absolute;
  top: 50%;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.CarbonPopCont .CarbonReportPop dl dd .chartScope ul li u.scope1 {
  background-color: var(--scope1);
}
.CarbonPopCont .CarbonReportPop dl dd .chartScope ul li u.scope2 {
  background-color: var(--scope2);
}
.CarbonPopCont .CarbonReportPop dl dd .chartScope ul li u.scope3 {
  background-color: var(--scope3);
}
.CarbonPopCont .CarbonReportPop dl dd .chartScope ul li u.scope4 {
  background-color: var(--scope4);
}
.CarbonPopCont .CarbonReportPop .ReportGrid {
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr;
}

.CarbonTopSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 20px;
}
.CarbonTopSelect .selArea {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 8px;
}
.CarbonTopSelect .selArea p.label {
  padding-right: 8px;
  margin-left: 12px;
}
.CarbonTopSelect .selArea p.label:first-child {
  margin-left: 0;
}
.CarbonTopSelect .btnArea {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 10px;
}
.CarbonTopSelect .btnArea > button {
  height: 32px;
  background-color: #4a5568;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 14px;
}
.CarbonTopSelect .btnArea > button.prm {
  background-color: #3ebdf3;
}
.CarbonTopSelect .btnArea p {
  position: relative;
}
.CarbonTopSelect .btnArea p input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.CarbonTopSelect .btnArea p label {
  padding-left: 28px;
  font-size: 14px;
  color: #666;
  background: url("../images/Carbon/chk_off.svg") no-repeat 0 50%;
}
.CarbonTopSelect .btnArea p input:checked + label {
  background-image: url("../images/Carbon/chk_on.svg");
}

.CarbonCalcuWrap {
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 420px);
  margin-top: 20px;
  position: relative;
  min-height: 542px;
}
.CarbonCalcuWrap .calcuList {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  height: calc(100% - 0px);
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}
.CarbonCalcuWrap .calcuList.full {
  width: calc(100% - 316px);
}
.CarbonCalcuWrap .calcuList::-webkit-scrollbar {
  height: 4px;
}
.CarbonCalcuWrap .calcuList::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
.CarbonCalcuWrap .calcuList .calcuBox {
  width: 290px;
  flex: 0 0 auto;
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont {
  box-sizing: border-box;
  padding: 20px 0 20px 15px;
  background-color: #f7f7f7;
  border-radius: 8px;
  height: calc(100% - 20px);
  position: relative;
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .deleteArea {
  padding-top: 5px;
  padding-bottom: 5px;
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .deleteArea .deleteBtn {
  position: absolute;
  right: 4px;
  top: 4px;
  padding-right: 5px;
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .calcuTop {
  margin-right: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .calcuTop ul li {
  display: flex;
  align-items: center;
  margin-bottom: 17px;
  min-height: 32px;
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .calcuTop ul li label {
  width: 100px;
  color: #4a5568;
  font-size: 14px;
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .calcuTop ul li > div {
  width: calc(100% - 100px);
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .calcuTop ul li > div:not(.MuiFormControl-root) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .calcuTop ul li > div > input {
  height: 32px;
  background-color: #fff;
  width: 100%;
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .calcuTop ul li > div .existFile {
  background: url("../images/Carbon/radio_off.svg") no-repeat 0 0;
  width: 20px;
  height: 20px;
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .calcuTop ul li > div .existFile.exist {
  background-image: url("../images/Carbon/radio_on.svg");
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .calcuTop ul li > div .upFile {
  cursor: pointer;
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .calcuTop ul li > div .upFile span {
  display: block;
  padding-left: 20px;
  background: url("../images/Carbon/ic_file.svg") no-repeat 0 50%;
  color: #2b2b2b;
  font-size: 14px;
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm {
  padding-top: 17px;
  margin-right: 5px;
  padding-right: 6px;
  max-height: calc(100% - 297px);
  overflow-y: scroll;
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm::-webkit-scrollbar {
  width: 4px;
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm ul li {
  margin-bottom: 17px;
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm ul li label {
  display: block;
  color: #4a5568;
  font-size: 14px;
  margin-bottom: 8px;
}
.CarbonCalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm ul li > div > input {
  height: 32px;
  background-color: #fff;
  width: 100%;
}
.CarbonCalcuWrap .calcuList .calcuBox.select .calcuCont {
  background-color: #f3f9fe;
}
.CarbonCalcuWrap .calcuList .calcuAddFloat {
  width: 296px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 20px);
  background-color: #fff;
  cursor: pointer;
  font-size: 32px;
  color: #666;
}
.CarbonCalcuWrap .calcuAdd {
  position: absolute;
  width: 296px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 20px);
  top: 0;
  right: 0;
  background-color: #fff;
  cursor: pointer;
  font-size: 32px;
  color: #666;
}

.CarbonBtmSubmit {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 16px 0 20px;
  gap: 10px;
}
.CarbonBtmSubmit > button {
  height: 40px;
  background-color: #3ebdf3;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 20px;
  box-sizing: border-box;
  min-width: 140px;
}
.CarbonBtmSubmit > button.dark {
  background-color: #4a5568;
}
.CarbonBtmSubmit > button.gray {
  background-color: #ebebed;
  color: #2b2b2b;
}

.CarbonPopTable {
  width: 600px;
  margin-top: 20px;
}
.CarbonPopTable table {
  width: 100%;
}
.CarbonPopTable table thead th {
  border-bottom: 1px solid #a0aec0;
  height: 46px;
  font-size: 14px;
  text-align: left;
  padding: 0 16px;
  color: #4a5568;
}
.CarbonPopTable table tbody td, .CarbonPopTable table tbody th {
  padding: 0 16px;
  height: 46px;
  font-size: 14px;
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: left;
}
.CarbonPopTable table tbody td .goWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.CarbonPopTable table tbody td .goWrap button.go {
  background: url("../images/Carbon/ic_next.svg") no-repeat 100% 50%;
  padding-right: 20px;
  color: #2b2b2b;
}

.LoginWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
}
.LoginWrap .LoginForm {
  width: 600px;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  position: relative;
}
.LoginWrap .LoginForm .goMain {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #666;
}
.LoginWrap .LoginForm h1 {
  font-size: 25px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}
.LoginWrap .LoginForm form {
  width: 360px;
}
.LoginWrap .LoginForm form > div {
  margin-bottom: 10px;
}
.LoginWrap .LoginForm form input[type=text], .LoginWrap .LoginForm form input[type=password] {
  width: 100%;
  height: 50px;
}
.LoginWrap .LoginForm form button {
  background-color: #3ebdf3;
  border-radius: 5px;
  height: 60px;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
}

.Calcu3ScopBoxOut {
  height: calc(100% - 0px);
  overflow-y: auto;
}
.Calcu3ScopBoxOut::-webkit-scrollbar {
  width: 4px;
}
.Calcu3ScopBoxOut::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

.Calcu3ScopBox {
  border-radius: 8px;
  background-color: #f7f7f7;
  padding: 30px;
}
.Calcu3ScopBox .topBtn {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 10px;
}
.Calcu3ScopBox .topBtn p {
  position: relative;
}
.Calcu3ScopBox .topBtn p input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.Calcu3ScopBox .topBtn p label {
  padding-left: 28px;
  font-size: 14px;
  color: #666;
  background: url("../images/Carbon/chk_off.svg") no-repeat 0 50%;
}
.Calcu3ScopBox .topBtn p input:checked + label {
  background-image: url("../images/Carbon/chk_on.svg");
}
.Calcu3ScopBox .topBtn button {
  border-radius: 4px;
  height: 32px;
  border: 1px solid #d3d3d4;
  background-color: #fff;
  padding: 0 14px;
  font-size: 14px;
  font-weight: 500;
}
.Calcu3ScopBox .topBtn button.blue {
  background-color: #3ebdf3;
  color: #fff;
  border-color: #3ebdf3;
}
.Calcu3ScopBox .Calcu3ScopeTable table {
  width: 100%;
  margin-top: 16px;
}
.Calcu3ScopBox .Calcu3ScopeTable table thead {
  border-bottom: 1px solid #a0aec0;
}
.Calcu3ScopBox .Calcu3ScopeTable table thead th {
  height: 46px;
  padding: 0 16px;
  text-align: left;
  font-size: 14px;
  color: #4a5568;
  font-weight: 400;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td {
  padding: 12px 16px;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td input[type=text] {
  height: 32px;
  background-color: #fff;
  width: 100%;
  font-size: 14px;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td input[type=text].MuiInputBase-inputAdornedStart {
  border: none;
  padding: 0 14px 0 0;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .MuiInputBase-root * {
  font-family: "Noto Sans KR", sans-serif;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .MuiInputBase-root {
  background-color: #fff;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: #d3d3d4;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #3ebdf3;
  border-width: 1px;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .basicSelect {
  font-weight: 400;
  font-size: 14px;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .basicSelect .MuiSelect-select {
  padding: 0 14px;
  height: 32px;
  line-height: 32px;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .matching {
  display: flex;
  align-items: center;
  gap: 2px;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .matching b {
  width: 35px;
  font-size: 14px;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .matching p {
  width: calc(100% - 37px);
  background-color: #d4ddea;
  border-radius: 4px;
  height: 8px;
  position: relative;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .matching p span {
  position: absolute;
  top: 0;
  left: 0;
  height: 8px;
  border-radius: 4px;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .matching p span.success {
  background-color: #3ebdf3;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .matching p span.fail {
  background-color: #ff3939;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .tmpSelect {
  position: relative;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .tmpSelect p {
  border: 1px solid #d3d3d4;
  border-radius: 4px;
  background-color: #fff;
  padding: 0 14px;
  box-sizing: border-box;
  height: 32px;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .tmpSelect p span {
  display: block;
  background: url("../images/Carbon/ic_down.svg") no-repeat 100% 50%;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  padding-right: 28px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .tmpSelect p.open {
  border-color: #3ebdf3;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .tmpSelect ul {
  position: absolute;
  top: 36px;
  border-radius: 4px;
  border: 1px solid #d3d3d4;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  min-width: 100%;
  display: none;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .tmpSelect ul li {
  min-height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  cursor: pointer;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .tmpSelect ul li:hover {
  background-color: #ebebed;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .tmpSelect ul li.selected {
  color: #3ebdf3;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .tmpSelect ul li.selected:after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url("../images/Carbon/ic_check.svg") no-repeat 50% 50%;
  margin-left: 5px;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .tmpSelect p.open + ul {
  display: block;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .pointInput {
  position: relative;
  border: 1px solid #d3d3d4;
  border-radius: 4px;
  background-color: #fff;
  padding: 0 14px;
  box-sizing: border-box;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .pointInput em {
  font-size: 14px;
  color: #666;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr td .pointInput input {
  border: none;
  height: 30px;
  padding: 0 0 0 14px;
}
.Calcu3ScopBox .Calcu3ScopeTable table tbody tr th {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 0 16px;
}
